import React from "react"
import Layout from "../components/Layout"
import TermsAndConditionsComponent from "../components/TermsAndConditions"
import SEO from "../components/seo"

export default function TermsAndConditions() {
  return (
    <Layout headerType="index">
      <SEO title="Compulease · Terms and Conditions" />
      <TermsAndConditionsComponent />
    </Layout>
  )
}
